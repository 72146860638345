<template>
  <div>
    <Card>
      <Row style="text-align: left">
        <Col span="3">
          <Button type="primary" ghost @click="showaddassetsmodel"><Icon type="ios-add" />添加资产</Button>
        </Col>
      </Row>
    </Card>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" ></Table>
    </div>
    <Modal
            v-model="showaddmodel"
            title="添加资产"
            @value="showaddassetsmodel"
            @on-ok="addassetsvalidate"
            width="800px"
            @on-cancel="cancelshowaddmodel">
      <div class="editor-container">
        <Form ref="formValidate" :model="assetdata" :rules="ruleassetdata" :label-width="80">
          <FormItem label="value" prop="value">
            <Input v-model="assetdata.value" placeholder="请输入资产值"></Input>
          </FormItem>
          <FormItem label="名称" prop="label">
            <Input v-model="assetdata.label" placeholder="请输入assets名称"></Input>
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Drawer
          title="修改资产"
          v-model="updatedrawer"
          width="720"
          :mask-closable="false"
          :styles="styles">
      <Form ref="updateassetsref" :model="updateassetData" :rules="ruleInline">
        <FormItem label="value" prop="value">
          <Input v-model="updateassetData.value" placeholder="请输入资产值"></Input>
        </FormItem>
        <FormItem label="名称" prop="label">
          <Input v-model="updateassetData.label" placeholder="请输入assets名称"></Input>
        </FormItem>
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="updatedrawer = false">关闭</Button>
        <Button type="primary" @click="submit">保存</Button>
      </div>
    </Drawer>
    <Modal
            v-model="showdelete"
            title="删除资产标签"
            @on-ok="deleteasset(delasset.id)"
            @on-cancel="canceldelete">
      <p>资产 名称： {{delasset.value}}</p>
    </Modal>
  </div>
</template>

<script>
  import {get,post,put,del} from "@/api/http.js";
  export default {
    name: "index",
    data () {
      return {
        delasset: {},
        showdelete: false,
        ruleassetdata: {
          value: [
            { required: true, message: 'value不能为空', trigger: 'blur' }
          ],
          label: [
            { required: true, message: 'assets名称不能为空', trigger: 'blur' }
          ]
        },
        ruleInline: {
          value: [
            { required: true, message: 'value不能为空', trigger: 'blur' }
          ],
          label: [
            { required: true, message: 'assets名称不能为空', trigger: 'blur' }
          ]
        },

        columns: [
          {type: 'selection', width: 60, align: 'center'},
          {title: 'value', key: 'value'}, {title: '名称', key: 'label'},
          {title: '操 作', key: 'action', width: 400, align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.showupdatedrawer(params.row)
                    }
                  }
                }, '修 改 |'),
                h('a', {
                  on: {
                    click: () => {
                      this.isdelete(params.row)
                    }
                  }
                }, '删 除')
              ]);
            }
          }
        ],
        updatedrawer: false,
        showaddmodel: false,
        assetdata: {},
        updateassetData: {},
        data:[],
        styles: {
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static'
        },
      }
    },
    methods: {
      showaddassetsmodel () {
        this.showaddmodel = true
      },
      canceldelete () {},
      cancelshowaddmodel () {
        this.$Message.info('Clicked cancel');
      },
      addassetsvalidate () {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.addassets(this.assetdata)
          }
        })
      },
      addassets(data) {
        post("/goansible/api/v1/ansible/assets", data)
          .then((resp)=>{
            this.$Message.info(resp.msg);
            this.getassets()
          })
          .catch((e)=>{
            console.log(e)
            return e
          })
      },
      isdelete (row) {
        this.delasset = row
        this.showdelete = true
      },
      updateassets(id,data) {
        put(`/goansible/api/v1/ansible/assets?id=`+ id,data)
          .then((resp)=>{
            if (resp.code !== 0){
              this.$Message.error(resp.msg);
            } else {
              this.$Message.info(resp.msg);
              this.updatedrawer = false
            }
          })
          .catch((e)=>{
            return e
          })
      },

      getassets() {
        get("/goansible/api/v1/ansible/assets", "")
          .then((resp)=>{
            this.data = resp.data;
          }).catch(e=>{
          return e
        })
      },
      deleteasset(id) {
        del(`/goansible/api/v1/ansible/assets?id=`+ id)
          .then((resp)=>{
            this.getassets()
          }).catch(e=>{
          return e
        })
      },

      showupdatedrawer(row) {
        this.updatedrawer = true
        this.updateassetData = row
      },
      submit () {
        this.$refs.updateassetsref.validate((valid) => {
          if (valid) {
            const id = this.updateassetData.id
            const data = {
              "value":this.updateassetData.value,
              "label":this.updateassetData.label}
            this.updateassets(id,data)
          }
        })
      },
    },
    created() {
      this.getassets()
    },
  }
</script>

<style scoped>

</style>
